import gql from "graphql-tag"
/* ========== Recent News ======== */
const RecentNews = gql`
query GetNews{
    newsBlogs (limit:10) {
        id
        URL
        Title
        News_Published_Date
        Tile_Image {
          url
          alternativeText
        }
        Select_News_Category {
          Name
          URL
        }
        imagetransforms
    }
}`
/* ========== Get Parent Menu ======== */
const BackParentMenu=  gql`
   query BackParent ($url: String!){   
    allMenus(where:{Primary_URL:$url}){
      Label
      Primary_URL
      Main_Parent {
        Label
        Primary_URL
        Disable_Parent_URL
      }
      Sub_Parent {
        Label
        Primary_URL
      }
    }
  }
`;
/* ========== Get News Category ======== */
const NewsCategories = gql`
query GetNewsCategory{
    newsCategories  {
      id
      Name
      URL
    }
}`

/* ========== Featured News ======== */
const FeaturedNews = gql`
query FeaturedNews($limit: Int!, $start: Int!){
    newsBlogs(where:{Featured_News:true}, sort:"News_Published_Date:desc", start:$start, limit:$limit) {
        id
        Title
        URL
        Short_Intro
        News_Published_Date
        Tile_Image {
          url
          alternativeText
        }
        Select_News_Category {
          Name
          URL
        }
        imagetransforms
    }
}`
/* ========== Featured News By Category ======== */
const FeaturedNewsByCategory = gql`
query FeaturedNewsByCategory($limit: Int!, $start: Int!, $category: String!){
 newsBlogs(
  where: {
    Featured_News: true,
    Select_News_Category: { URL: $category }
  }
    publicationState: LIVE
    sort: "News_Published_Date:desc"
    start: $start
    limit: $limit
  ) {      
        id
        Title
        URL
        Short_Intro
        News_Published_Date
        Tile_Image {
          url
          alternativeText
        }
        Select_News_Category {
          Name
          URL
        }
        imagetransforms
    }
}`
/* ========== All News ======== */
const AllNews = gql`
query GetAllNews($limit: Int!, $start: Int!){
    newsBlogs(where:{Featured_News_ne:true}, sort:"News_Published_Date:desc", start:$start, limit:$limit) {
        id
        Title
        URL
        Short_Intro
        News_Published_Date
        Tile_Image {
          url
          alternativeText
        }
        Select_News_Category {
          Name
          URL
        }
        imagetransforms
    }
}`
/* ========== All News By Category ======== */
const AllNewsByCategory = gql`
query AllNewsByCategory($limit: Int!, $start: Int!, $category: String!){
    newsBlogs( where:{
      Featured_News_ne:true,   
      Select_News_Category: { URL: $category }
      },sort:"News_Published_Date:desc", start:$start, limit:$limit) {
        id
        Title
        URL
        Short_Intro
        News_Published_Date
        Tile_Image {
          url
          alternativeText
        }
        Select_News_Category {
          Name
          URL
        }
        imagetransforms
    }
}`
/* ========== Search News ======== */
const SearchNews= gql`
query GetAllNews($title: String!, $limit: Int!, $start: Int!){
    newsBlogs(where:{Title_contains:$title}, sort:"News_Published_Date:desc", start:$start, limit:$limit) {
        id
        Title
        URL
        Short_Intro
        News_Published_Date
        Tile_Image {
          url
          alternativeText
        }
        Select_News_Category {
          Name
          URL
        }
        imagetransforms
    }
}`
/* ========== News Details ======== */
const NewsDetail = gql`
  query NewsDetail ($url: String!) {
    newsBlogs(where:{URL:$url}){
      id
      Title
      URL
      Meta_Title
      Meta_Description
      News_Published_Date
      Banner_Image {
        url
        alternativeText
      }
      Short_Intro
      CTA_Below_Short_Intro {
        CTA_Label
        CTA_Link {
          Primary_URL
        }
        CTA_Custom_Link
      }
      Full_Content
      imagetransforms
  }
}`
/* ========== Other News ======== */
const OtherNews = gql`
query OtherNews ($url: String!) {
    newsBlogs (sort:"News_Published_Date:desc",limit:2,where:{URL_ne: $url}){
        URL
        Title
        id
        News_Published_Date
        Tile_Image {
          url
          alternativeText
        }
        Select_News_Category {
          Name
          URL
        }
        imagetransforms
        
    }
}`

/* ========== All Teams ======== */
const AllTeamsList = gql`
query AllTeamsList  {
  teams  {
    id
    URL
    Name
    Designation
    Staff_Image {
      url
      alternativeText
    }
    Select_Office {
      Name
      URL
    }
    Select_Department {
      Name
    }
    imagetransforms
  }
}`

/* ========== Filter Teams ======== */
const AllTeams = gql`
query AllTeams ($branch: String!) {
  teams (where:{Select_Office:{URL_contains:$branch}}) {
    id
    URL
    Name
    Designation
    Staff_Image {
      url
      alternativeText
    }
    Select_Office {
      Name
      URL
    }
    Select_Department {
      Name
    }
    imagetransforms
  }
}`
/* ========== Team Page Branch Filter ======== */
const BranchFilter = gql`
query BranchFilter  {
  ourOffices {
    Name
    URL
  }
}`
/* ========== Team Page Department Filter ======== */
const DepartmentFilter = gql`
query DepartmentFilter  {
  departments {
    Name
    URL
  }
}`
/* ========== Team Detail Page ======== */

const TeamDetail = gql`
query TeamDetail($url: String!) {
  teams (where:{URL:$url}) {
    id
    Name
    Meta_Title
    Meta_Description
    URL
    Designation
    Negotiator_CRM_ID
    Email
    Phone_Number
    Bio
    Areas_of_Speciality
    Youtube_Video_URL
    Staff_Image{
      url
      alternativeText
    }
    Staff_Image_Detail_Page {
      url
      alternativeText
    }
    imagetransforms
  }
}`
/* ========== Other Staffs ======== */
const OtherStaffs = gql`
query OtherStaffs ($url: String!) {
  teams (limit:4,where:{URL_ne: $url}){
    id
    URL
    Name
    Designation
    Staff_Image {
      url
      alternativeText
    }
    imagetransforms
  }
}`
/* ========== Careers ======== */
const Careers = gql`
query Careers  {
  careers (sort:"Sorting:asc") {
    Job_Opening
    URL
    Listing_Intro
    Type
    Location
    Contact_Email
    Sorting
    Car_And_Van_Leasing_Vacancy
  }
}`
/* ========== Career Drtail ======== */

const CareerDetail = gql`
query CareerDetail($url: String!) {
  careers (where:{URL:$url}) {
    id
    Job_Opening
    Meta_Title
    Meta_Description
    URL
    Listing_Intro
    Type
    Salary
    Location
    Description
    Select_Member {
        id
        URL
        Staff_Image {
          url
          alternativeText
        }
        imagetransforms
        Name
        Designation
        Phone_Number
    }
  }
}`

/* ========== Office Teams ======== */
const OfficeTeam = gql`
query OfficeTeam  ($id: String!) {
  teams  (where:{Select_Office:{id:$id}}) {
    id
    URL
    Name
    Designation
    Staff_Image {
      url
      alternativeText
    }
    Select_Office {
      Name
      URL
    }
    Select_Department {
      Name
    }
    imagetransforms
  }
}`

/* ========== Member Contact Detail ======== */

const MemberContactDetail = gql`
query MemberContactDetail($id: ID!) {
  teams (where:{id:$id}) {
    id
    Name
    Email
    Phone_Number   
  }
}`

/* ========== Search Office Detail ======== */

const GetPropertyOffice = gql`
query GetPropertyOffice($officecrm: String) {
    ourOffices (where:{Office_CRM_contains:$officecrm}) {
    id
    URL
    Name
    URL
    Office_CRM   
    Sales_Email_Address
    Lettings_Email_Address
    Sales_Phone_Number
    Lettings_Phone_Number
  }
}`

/* ========== Search Office Detail ======== */

const GetPopularSearchOffice = gql`
query GetPopularSearchOffice($name: String!) {
    ourOffices (where:{Name_contains:$name}) {
    id
    URL
    Name
    URL
  }
}`


/* ========== Property Detail For Booking ======== */

const GetPropertyBooking = gql`
query GetPropertyOffice($id: String!) {
  properties (where:{id:$id}) {
    id
    slug
    display_address
    search_type
    department
    title
    images
    bedroom
    bathroom
    office_crm_id
    price
  }
}`

/* ========== Negotiator Detail ======== */

const NegotiatorDetail = gql`
query NegotiatorDetail($crmid: String!) {
  teams (where:{Negotiator_CRM_ID:$crmid}) {
    id
    Name
    Meta_Title
    Meta_Description
    URL
    Designation
    Email
    Phone_Number
    Negotiator_CRM_ID
    Areas_of_Speciality
    Youtube_Video_URL
    Staff_Image{
      url
      alternativeText
    }
    Staff_Image_Detail_Page {
      url
      alternativeText
    }
    imagetransforms
  }
}`
/* ========== Property Area Guide ======== */
const AreaGuideDetail = gql`
query AreaGuideDetail($postcode: String!) {
    areaguides (where:{Postcode_Mapping_contains:$postcode}) {
      id
      Name
      URL
      Short_Intro
      Postcode_Mapping
      Youtube_Video_URL
      Tile_Image {
        url
        alternativeText
      }
      imagetransforms
  }
}`
/* ========== PopularSearch Area Guide ======== */
const AreaGuidePopularSearch = gql`
query AreaGuidePopularSearch($name: String!) {
    areaguides (where:{Name_contains:$name}) {
      id
      Name
      URL     
  }
}`
/* ========== No Results Properties ======== */
const NoResultProperties = gql`
query NoResultProperties($status: String!, $department: String!){
    properties(limit:6, sort:"updatedAt:desc", where:{status:$status, department:$department, publish:true}) {
        id
        area
        display_address
        search_type
        status
        slug
        title
        department
        price
        images
        description
        imagetransforms
        office_crm_id
    }

}`


export const Queries = {
    RecentNews,
    BackParentMenu,
    NewsCategories,
    FeaturedNews,
    FeaturedNewsByCategory,
    AllNews,
    AllNewsByCategory,
    SearchNews,
    NewsDetail,
    OtherNews,
    AllTeamsList,
    AllTeams,
    BranchFilter,
    DepartmentFilter,
    TeamDetail,
    OtherStaffs,
    Careers,
    CareerDetail,
    OfficeTeam,
    MemberContactDetail,
    GetPropertyOffice,
    GetPropertyBooking,
    NegotiatorDetail,
    AreaGuideDetail,
    AreaGuidePopularSearch,
    GetPopularSearchOffice,
    NoResultProperties
};


