import { useQuery} from '@apollo/client';
import { Queries } from "./common_queries";

export const RecentNews = () => {
    return useQuery(Queries.RecentNews);
}
export const BackParentMenu = (url) => {
    return useQuery(Queries.BackParentMenu,{
        variables: {url: url}
    });
}
export const NewsCategories = () => {
    return useQuery(Queries.NewsCategories);
}
export const AllNews = (start, limit) => {
    return useQuery(Queries.AllNews,{
        variables: {start: start, limit: limit}
    });
}
export const AllNewsByCategory = (start, limit, category) => {
    return useQuery(Queries.AllNewsByCategory,{
        variables: {start: start, limit: limit, category: category}, 
    });
}
export const FeaturedNews = (start, limit) => {
    return useQuery(Queries.FeaturedNews,{
        variables: {start: start, limit: limit}
    });
}
export const FeaturedNewsByCategory = (start, limit, category ) => {
    return useQuery(Queries.FeaturedNewsByCategory,{
        variables: {start: start, limit: limit, category: category}
    });
}
export const SearchNews = (start, limit, title) => {
    return useQuery(Queries.SearchNews,{
        variables: {start: start, limit: limit,title: title}
    });
}
export const NewsDetail = (url) => {
    return useQuery(Queries.NewsDetail,{
        variables: {url: url}
    });
}
export const OtherNews = (url) => {
    return useQuery(Queries.OtherNews,{
        variables: {url: url}
    });
}
export const AllTeamsList = () => {
    return useQuery(Queries.AllTeamsList);
}
export const AllTeams = (branch) => {
    return useQuery(Queries.AllTeams,{
        variables: {branch: branch}
    });
}
export const BranchFilter = () => {
    return useQuery(Queries.BranchFilter);
}
export const DepartmentFilter = () => {
    return useQuery(Queries.DepartmentFilter);
}
export const TeamDetail = (url) => {
    return useQuery(Queries.TeamDetail,{
        variables: {url: url}
    });
}
export const OtherStaffs = (url) => {
    return useQuery(Queries.OtherStaffs,{
        variables: {url: url}
    });
}
export const Careers = () => {
    return useQuery(Queries.Careers);
}
export const CareerDetail = (url) => {
    return useQuery(Queries.CareerDetail,{
        variables: {url: url}
    });
}
export const OfficeTeam = (id) => {
    return useQuery(Queries.OfficeTeam,{
        variables: {id: id}
    });
}
export const MemberContactDetail = (id) => {
    return useQuery(Queries.MemberContactDetail,{
        variables: {id: id}
    });
}
export const GetPropertyOffice = (officecrm) => {
    return useQuery(Queries.GetPropertyOffice,{
        variables: {officecrm: officecrm}
    });
}
export const GetPropertyBooking = (id) => {
    return useQuery(Queries.GetPropertyBooking,{
        variables: {id: id}
    });
}
export const NegotiatorDetail = (crmid) => {
    return useQuery(Queries.NegotiatorDetail,{
        variables: {crmid: crmid}
    });
}
export const AreaGuideDetail = (postcode) => {
    return useQuery(Queries.AreaGuideDetail,{
        variables: {postcode: postcode}
    });
}
export const AreaGuidePopularSearch = (name) => {
    return useQuery(Queries.AreaGuidePopularSearch,{
        variables: {name: name}
    });
}
export const GetPopularSearchOffice = (name) => {
    return useQuery(Queries.GetPopularSearchOffice,{
        variables: {name: name}
    });
}
export const NoResultProperties = (status, department) => {
    return useQuery(Queries.NoResultProperties, {
        variables: {status: status, department: department}
    });
}

